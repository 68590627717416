<template>
  <div class="case-warp bg-gray padding60">
    <div class="w1000">
      <div class="case-list" id="content">
        <article v-for="item in casesList" :key="item.id">
          <div class="citem">
            <router-link :to="`/case/details/${item.id}`" :title="item.name">
              <img :src="item.mainImg" :alt="item.name"
                   class="attachment-post-thumbnail wp-post-image"
              />
              <span></span>
            </router-link>
            <div class="citemtxt">
              <router-link :to="`/case/details/${item.id}`" :title="item.name">
                <h4>{{ item.name }}</h4>
              </router-link>
              <p>
                  <span><router-link :to="`/case/list/${item.typeId}`">{{
                      item.caseType
                    }}</router-link></span>
                <em><label>{{ item.pageViews }}</label>浏览</em>
              </p>
            </div>
          </div>
        </article>
      </div>
    </div>
    <Pagination :totalPage="pageTotal" :changePageNumber="changePageNumber"/>
  </div>
</template>

<script>
import Pagination from "../Pagination";
import axios from "../../net/axios";
import api from "../../net/api";

export default {
  name: "WebList",
  props: ['typeId'],
  components: {Pagination},
  data() {
    return {
      pageSize: 12,
      pageTotal: 0,
      casesList: [],
      pageIndex: 1,
    }
  },
  methods: {
    changePageNumber(value) {
      this.pageIndex = value;
      this.getData();
    },
    getData() {
      let params = {
        caseType: this.typeId,
        pageSize: this.pageSize,
        pageIndex: this.pageIndex,
      }
      axios.post(api.caseList, params)
          .then(res => {
            this.casesList = res.data.rows;
            this.pageTotal = Math.ceil(res.data.total / this.pageSize)
          })
          .catch(e => {
            console.log(e)
          })
    },
  },
  watch: {
    typeId: {
      immediate: true, //初始化时调用一下
      deep: true,//深度监视
      handler() {
        // console.log('切换类型caseType')
        this.pageIndex = 1;
        this.pageTotal=0
        this.casesList=[]
        this.getData();
      }
    }
  },
}
</script>

<style scoped>

</style>
