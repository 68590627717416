<template>
  <div class="mobile-container">
    <article v-for="item in casesList" :key="item.id">
      <div class="mobile-item">
        <router-link :to="`/case/details/${item.id}`" :title="item.name">
          <img :src="item.mainImg" :alt="item.name"
               class="attachment-post-thumbnail wp-post-image"
          />
          <span></span>
        </router-link>
        <div class="mobile-text">
          <router-link :to="`/case/details/${item.id}`" :title="item.name">
            <h4>{{ item.name }}</h4>
          </router-link>
          <p>
            <span><router-link :to="`/case/list/${item.typeId}`">{{ item.caseType }}</router-link></span>
            <em><label>{{ item.pageViews }}</label>浏览</em>
          </p>
        </div>
      </div>
    </article>
    <div style="overflow: auto;">
      <infinite-loading force-use-infinite-wrapper=".mobile-container" @infinite="infiniteHandler" :distance="200"
                        ref="infiniteLoading">
        <span slot="no-more">没有更多数据</span>
        <span slot="no-results">没有更多数据</span>
      </infinite-loading>
    </div>
  </div>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";
import axios from "../../net/axios";
import api from "../../net/api";

export default {
  name: "MobileList",
  props: ['typeId'],
  components: {InfiniteLoading},
  data() {
    return {
      pageSize: 12,
      pageTotal: 0,
      casesList: [],
      pageIndex: 1,
    }
  },
  methods:{
    infiniteHandler($state) {
      this.pageIndex++
      console.log('手机加载更多回调', this.pageIndex, this.pageTotal, $state)

      if (this.pageIndex < this.pageTotal) {
        let params = {
          caseType: this.typeId,
          pageSize: this.pageSize,
          pageIndex: this.pageIndex,
        }
        axios.post(api.caseList, params)
            .then(res => {
              console.log('手机加载', res.data)
              this.pageTotal = Math.ceil(res.data.total / this.pageSize)
              if (this.pageIndex == 1) {
                this.casesList = res.data.rows;
              } else {
                this.casesList = this.casesList.concat(res.data.rows)
              }
              $state.loaded();
            })
            .catch(e => {
              console.log(e)
              $state.complete();
            })
      } else {
        //没有更多数据
        $state.complete();
      }
    },
    getData() {
      let params = {
        caseType: this.typeId,
        pageSize: this.pageSize,
        pageIndex: this.pageIndex,
      }
      axios.post(api.caseList, params)
          .then(res => {
            // console.log('手机加载', res.data)
            this.pageTotal = Math.ceil(res.data.total / this.pageSize)
            this.casesList = res.data.rows;
          })
          .catch(e => {
            console.log(e)
          })
    },
  },
  watch: {
    typeId: {
      immediate: true, //初始化时让handler调用一下
      deep: true,//深度监视
      handler() {
        // console.log('切换类型caseType')
        this.pageIndex = 1;
        this.pageTotal=0
        this.casesList=[]
        this.$refs.infiniteLoading&&this.$refs.infiniteLoading.stateChanger.reset()
        this.getData();
      }
    }
  },

}
</script>

<style scoped>

</style>
