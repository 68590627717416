<template>
  <!--手机版-->
  <div class="mobile-main">
    <div class="mobile-list">
      <MNewsItem v-for="item in newsList" :key="item.id" :data="item" />
    </div>
    <div style="overflow: auto;">
      <infinite-loading force-use-infinite-wrapper=".mobile-container" @infinite="infiniteHandler" :distance="200"
                        ref="infiniteLoading">
        <span slot="no-more">没有更多数据</span>
        <span slot="no-results">没有更多数据</span>
      </infinite-loading>
    </div>
  </div>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";
import axios from "../../net/axios";
import api from "../../net/api";
import MNewsItem from "./MNewsItem";

export default {
  name: "MobileList",
  props: ['typeId'],
  components: {InfiniteLoading,MNewsItem},
  data() {
    return {
      pageSize: 12,
      pageTotal: 0,
      newsList: [],
      pageIndex: 1,
    }
  },
  methods:{
    infiniteHandler($state) {
      this.pageIndex++
      // console.log('手机加载更多回调', this.pageIndex, this.pageTotal, $state)

      if (this.pageIndex < this.pageTotal) {
        let params = {
          caseType: this.typeId,
          pageSize: this.pageSize,
          pageIndex: this.pageIndex,
        }
        axios.post(api.caseList, params)
            .then(res => {
              console.log('手机加载', res.data)
              this.pageTotal = Math.ceil(res.data.total / this.pageSize)
              if (this.pageIndex == 1) {
                this.newsList = res.data.rows;
              } else {
                this.newsList = this.newsList.concat(res.data.rows)
              }
              $state.loaded();
            })
            .catch(e => {
              console.log(e)
              $state.complete();
            })
      } else {
        //没有更多数据
        $state.complete();
      }
    },
    getData() {
      let params = {
        newsType: this.typeId,
        pageSize: this.pageSize,
        pageIndex: this.pageIndex,
        state:1
      }
      axios.post(api.newsList, params)
          .then(res => {
            // console.log('手机加载', res.data)
            this.pageTotal = Math.ceil(res.data.total / this.pageSize)
            this.newsList = res.data.rows;
          })
          .catch(e => {
            console.log(e)
          })
    },
  },
  watch: {
    typeId: {
      immediate: true, //初始化时让handler调用一下
      deep: true,//深度监视
      handler() {
        // console.log('切换类型caseType')
        this.pageIndex = 1;
        this.pageTotal=0
        this.newsList=[]
        this.$refs.infiniteLoading&&this.$refs.infiniteLoading.stateChanger.reset()
        this.getData();
      }
    }
  },

}
</script>

<style scoped>

</style>
