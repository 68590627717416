<template>
  <div class="footer-container">
    <div class="new_main_footer">
      <div class="new_footer_top new_container clearfix">
        <div class="new_top_left fl">
          <div class="left_top_logo">
            <img src="../assets/images/new_footer_logo.png" alt=""/>
          </div>
          <div class="left_bottom_info">
<!--            <div class="info_item">-->
<!--              <img src="../assets/images/new_footer_phone.png" alt=""/>电话：<a href="tel:+8617688158011">176-8815-8011</a>-->
<!--            </div>-->
            <div class="info_item">
              <img src="../assets/images/new_footer_email.png" alt=""/>邮箱：<a
                href="mailto:admin@fxcxy.com">admin@fxcxy.com</a>
            </div>
            <div class="info_item">
              <img src="../assets/images/new_footer_address.png" alt=""/>
              <span>地址：</span>
              <span>湖南省长沙市岳麓区中南大学科技园研发总部6栋123号</span>
            </div>
          </div>
        </div>
        <div class="new_top_right fr">
          <div class="right_qrcode">
            <img src="../assets/images/new_footer_qrcode1.png" alt=""/>
            <p>售前客服微信</p>
          </div>
          <div class="right_qrcode">
            <img src="../assets/images/new_footer_qrcode2.png" alt=""/>
            <p>售后客服微信</p>
          </div>
        </div>
      </div>
      <div class="new_footer_bottom">
        <p>Copyright © 2021-2022 湖南峰行科技有限公司 版权所有</p>
        <p>备案信息：湘ICP备2021008142号</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  beforeRouteUpdate(to, from, next) {
    console.log('路由变更',to)
    next()  // 一定要有next
  }
}
</script>

<style scoped lang="less">
.footer-container {
  background: #222b33;
}

.new_footer_top {
  padding: 20px 0 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.left_top_logo {
  width: 120px;

  img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
  }
}

.left_bottom_info {
  margin-top: 15px;

  .info_item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    font-size: 14px;
    color: #c2c5c8;
    line-height: 25px;

    img {
      margin-right: 5px;
      width: 20px;
      height: 20px;
      -o-object-fit: contain;
      object-fit: contain;
    }

    a {
      font-size: 14px;
      color: #c2c5c8;
      line-height: 30px;
    }

    span {
      margin: 0 5px;
    }
  }

  .info_item:last-child {
    align-items: flex-start;

    img {
      margin-top: 5px;
    }

    span {
      margin: 0;
      white-space: nowrap;
    }

    span:last-child {
      margin: 0;
      white-space: normal;
    }
  }
}

.new_top_left {
  padding: 0 20px
}

.new_top_right {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 20px;

  .right_qrcode {
    width: 80px;

    img {
      width: 100%;
      height: 100%;
      -o-object-fit: contain;
      object-fit: contain;
    }

    p {
      font-size: 12px;
      color: #c2c5c8;
      line-height: 20px;
      text-align: center;
    }
  }

  .right_qrcode:not(:last-child) {
  }
}

.new_footer_bottom {
  border-top: 1px solid #364049;
  padding: 20px 0;
  display: none;
  p {
    font-size: 14px;
    font-weight: 400;
    color: #494f55;
    line-height: 24px;
    text-align: center;
  }
}



//设置媒体查询
@media only screen {
  // 断点 768px
  @media (min-width: 768px) {
    .footer-container {
      background: #222b33;
    }

    .new_main_footer {
      max-width: 1200px;
      margin: 0 auto;
    }

    .new_main_footer .new_footer_top {
      padding: 60px 0 40px;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
    }

    .new_top_left {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
    }

    .new_main_footer .new_footer_top .new_top_left .left_top_logo {
      width: 150px;
    }

    .new_main_footer .new_footer_top .new_top_left .left_top_logo img {
      width: 100%;
      height: 100%;
      -o-object-fit: contain;
      object-fit: contain;
    }

    .new_main_footer .new_footer_top .new_top_left .left_bottom_info {
      margin-top: 35px;
    }

    .new_main_footer .new_footer_top .new_top_left .left_bottom_info .info_item {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      font-size: 14px;
      color: #c2c5c8;
      line-height: 30px;
    }

    .new_main_footer .new_footer_top .new_top_left .left_bottom_info .info_item img {
      margin-right: 5px;
      width: 20px;
      height: 20px;
      -o-object-fit: contain;
      object-fit: contain;
    }

    .new_main_footer .new_footer_top .new_top_left .left_bottom_info .info_item a {
      font-size: 14px;
      color: #c2c5c8;
      line-height: 30px;
    }

    .new_main_footer .new_footer_top .new_top_left .left_bottom_info .info_item span {
      margin: 0;
    }

    .new_main_footer .new_footer_top .new_top_right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .new_main_footer .new_footer_top .new_top_right .right_qrcode {
      width: 110px;
    }

    .new_main_footer .new_footer_top .new_top_right .right_qrcode:not(:last-child) {
      margin-right: 50px;
    }

    .new_main_footer .new_footer_top .new_top_right .right_qrcode img {
      width: 100%;
      height: 100%;
      -o-object-fit: contain;
      object-fit: contain;
    }

    .new_main_footer .new_footer_top .new_top_right .right_qrcode p {
      font-size: 14px;
      color: #c2c5c8;
      line-height: 40px;
      text-align: center;
    }

    .new_main_footer .new_footer_bottom {
      border-top: 1px solid #364049;
      padding: 20px 0;
    }

    .new_main_footer .new_footer_bottom p {
      font-size: 14px;
      font-weight: 400;
      color: #494f55;
      line-height: 24px;
      text-align: center;
    }
  }
}
</style>
