<template>
  <div class="cases-container new_index_cases">
    <div class="service-container new_container">
      <div class="new_module_top">
        <h2>案例展示</h2>
        <h4>— CASES —</h4>
        <p>基于多年的互联网项目经验，为客户打造精致的产品</p>

      </div>
      <!--手机版-->
      <div class="mobile-swiper-wrapper">
        <router-link class="mobile-swiper-slide" v-for="item in cases"
                     :to="`/case/details/${item.id}`" :key="item.id">
          <div class="mobile-new_top_img">
            <img :src="item.mainImg" alt=""/>
          </div>
          <div class="mobile-new_bottom_info">
            <h4>{{ item.name }}</h4>
<!--            <div class="mobile-new_info_desc">-->
<!--              <span>项目名称：</span>-->
<!--              <p>{{ item.projectName }}</p>-->
<!--            </div>-->
            <div class="mobile-new_info_desc">
              <span>案例类型：</span>
              <p>{{ item.caseType }}</p>
            </div>
            <div class="mobile-new_info_desc">
              <span>案例介绍：</span>
              <p class="new_info_content">{{ item.summary }}</p>
            </div>
          </div>
        </router-link>
      </div>
      <!--pc版-->
      <div class="new_cases_swiper swiper-container" ref="swiperRef">
        <div class="swiper-wrapper">
          <router-link class="swiper-slide" v-for="item in cases"
                       :to="`/case/details/${item.id}`" :key="item.id">
            <div class="new_top_img">
              <img :src="item.mainImg" alt=""/>
            </div>
            <div class="new_bottom_info">
              <h4>{{ item.name }}</h4>
<!--              <div class="new_info_desc">-->
<!--                <span>项目名称：</span>-->
<!--                <p>{{ item.projectName }}</p>-->
<!--              </div>-->
              <div class="new_info_desc">
                <span>案例类型：</span>
                <p>{{ item.caseType }}</p>
              </div>
              <div class="new_info_desc">
                <span>案例介绍：</span>
                <p class="new_info_content">{{ item.summary }}</p>
              </div>
            </div>
          </router-link>
        </div>
      </div>

      <div class="cases-swiper-button swiper-button-next">
        <img src="../../assets/images/new_index_next.png" alt=""/>
      </div>
      <div class="cases-swiper-button swiper-button-prev">
        <img src="../../assets/images/new_index_prev.png" alt=""/>
      </div>

      <div class="new_module_top">
        <p><br/></p>
        <router-link to="/case" style="color: #888">查看更多>></router-link>
      </div>
    </div>
  </div>
</template>

<script>

import axios from "../../net/axios";
import api from "../../net/api";

export default {
  name: "Cases",
  data() {
    return {
      cases:[],
      slide: 0,
      sliding: null,
    }
  },
  mounted() {
    this.timer=setTimeout(()=>this.initSwiper(),500)
    this.getData();
  },

  methods: {
    getImage(imgName) {
      return require('../../assets/images/' + imgName)
    },
    getData() {
      axios.get(api.caseRecommendList)
          .then(res => {
            console.log('案例数据',res.data)
            this.cases = res.data;
          })
          .catch(e => {
            console.log(e)
          })
    },
    initSwiper(){
      if (this.swiper) {
        this.swiper.slideTo(0, 0)
        this.swiper.destroy()
        this.swiper = null;
      }
      this.swiper = new Swiper(this.$refs.swiperRef, {
        autoplay: 3000,
        slidesPerView: 4,
        spaceBetween: 20,
        loop: true,
        prevButton: '.cases-swiper-button.swiper-button-prev',
        nextButton: '.cases-swiper-button.swiper-button-next',
      });
    }
  },
  beforeDestroy() {
    if (this.swiper) {
      // 销毁swiper
      this.swiper && this.swiper.destroy()
      this.timer && clearTimeout(this.timer)
    }
  }
}
</script>

<style scoped lang="less">
.mobile-swiper-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  .mobile-swiper-slide {
    background: #fff;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(53, 67, 78, 0.08);
    box-shadow: 0px 0px 10px 0px rgba(53, 67, 78, 0.08);
    border-radius: 10px;
    overflow: hidden;
    width: 90%;
    display: block;
    margin-bottom: 20px;

    .mobile-new_top_img img {
      height: 160px;
      overflow: hidden;
      width: 100%;
    }

    .mobile-new_bottom_info {
      padding: 35px 20px;

      h4 {
        color: #333;
        font-size: 16px;
        font-weight: 700;
        line-height: 28px;
        margin-bottom: 20px;
        padding-bottom: 20px;
        position: relative;

        &:before {
          background: #0a71ff;
          bottom: 0;
          content: "";
          height: 2px;
          left: 0;
          position: absolute;
          width: 30px;
        }
      }

      .mobile-new_info_desc {
        align-items: flex-start;
        display: flex;
        justify-content: flex-start;

        span, p {
          color: #666;
          font-size: 14px;
          line-height: 28px;
        }

        span {
          white-space: nowrap;
        }
      }
    }
  }
}

.cases-swiper-button,.new_cases_swiper {
  display: none;
}

//设置媒体查询
@media only screen {
  // 断点 768px
  @media (min-width: 768px) {
    .mobile-swiper-wrapper {
      display: none;
    }
    .new_index_cases{
      background: url('../../assets/images/new_index_cases_bg.jpg') no-repeat;
      background-size: cover;
    }

    .new_index_cases .new_container {
      position: relative;
      display: block;
    }

    .new_index_cases .new_container .swiper-button-next,
    .new_index_cases .new_container .swiper-button-prev {
      top: 65%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      display: block;
    }

    .new_index_cases .new_container .swiper-button-next:hover,
    .new_index_cases .new_container .swiper-button-prev:hover {
      -webkit-filter: invert(55%) sepia(94%) saturate(402%) hue-rotate(177deg) brightness(101%) contrast(88%);
      filter: invert(55%) sepia(94%) saturate(402%) hue-rotate(177deg) brightness(101%) contrast(88%);
    }

    .new_index_cases .new_container .swiper-button-next::after,
    .new_index_cases .new_container .swiper-button-prev::after {
      content: none;
    }

    .new_index_cases .new_container .swiper-button-next img,
    .new_index_cases .new_container .swiper-button-prev img {
      width: 50px;
      height: 50px;
      -o-object-fit: contain;
      object-fit: contain;
    }

    .new_index_cases .new_container .swiper-button-next {
      right: -8%;
    }

    .new_index_cases .new_container .swiper-button-prev {
      left: -8%;
    }

    .new_index_cases .new_cases_swiper {
      padding-top: 50px;
      display: block;
    }

    .new_index_cases .new_cases_swiper a {
      background: #fff;
      -webkit-box-shadow: 0px 0px 10px 0px rgba(53, 67, 78, 0.08);
      box-shadow: 0px 0px 10px 0px rgba(53, 67, 78, 0.08);
      border-radius: 10px;
      overflow: hidden;
    }

    .new_index_cases .new_cases_swiper a:hover {
      -webkit-box-shadow: 0px 0px 10px 0px rgba(53, 67, 78, 0.25);
      box-shadow: 0px 0px 10px 0px rgba(53, 67, 78, 0.25);
    }

    .new_index_cases .new_cases_swiper a:hover .new_top_img img {
      transform: scale(1.15);
      -o-transform: scale(1.15);
      -ms-transform: scale(1.15);
      -moz-transform: scale(1.15);
      -webkit-transform: scale(1.15);
    }

    .new_index_cases .new_cases_swiper a .new_top_img {
      height: 160px;
      overflow: hidden;
    }

    .new_index_cases .new_cases_swiper a .new_top_img img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      -webkit-transition: all 0.8s;
      transition: all 0.8s;
    }

    .new_index_cases .new_cases_swiper a .new_bottom_info {
      padding: 35px 20px;
    }

    .new_index_cases .new_cases_swiper a .new_bottom_info h4 {
      position: relative;
      padding-bottom: 20px;
      margin-bottom: 20px;
      font-size: 16px;
      font-weight: bold;
      color: #333;
      line-height: 28px;
    }

    .new_index_cases .new_cases_swiper a .new_bottom_info h4::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 30px;
      height: 2px;
      background: #0A71FF;
    }

    .new_index_cases .new_cases_swiper a .new_bottom_info .new_info_desc {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }

    .new_index_cases .new_cases_swiper a .new_bottom_info .new_info_desc span {
      white-space: nowrap;
    }

    .new_index_cases .new_cases_swiper a .new_bottom_info .new_info_desc span{
      font-size: 14px;
      color: #666;
      line-height: 28px;
    }
    .new_index_cases .new_cases_swiper a .new_bottom_info .new_info_desc p {
      font-size: 14px;
      color: #666;
      line-height: 28px;
      overflow:hidden;
      text-overflow: ellipsis;
    }
    .new_info_content{
      height: 132px;
    }
  }
}

</style>
