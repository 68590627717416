<template>
  <div class="artlist">
    <div class="times">{{ data.updatedAt | timeFormat('YYYY') }}年<br/>
      {{ data.updatedAt | timeFormat('MM-DD') }}
    </div>
    <div class="listt">
      <div class="listtbt">
        <router-link :to="`/news/details/${data.id}`"
                     :title="data.title">{{ data.title }}
        </router-link>
      </div>
      <div class="listtnr">{{ data.subTitle }}
        <router-link :to="`/news/details/${data.id}`" :title="data.title">[详情]</router-link>
      </div>
    </div>
  </div>
</template>

<script> 

export default {
  name: "NewsItem",
  props: ['data'], 
}
</script>

<style scoped lang="less">

//设置媒体查询
@media only screen {
  // 断点 768px
  @media (min-width: 768px) {
    .artlist {
      width: 675px;
      height: 100px;
      border-bottom: 1px dashed #ebebeb;
      padding: 15px 0px;
    }

    .times {
      float: left;
      width: 128px;
      height: 71px;
      font-size: 18px;
      color: #FFF;
      background: url("../../assets/images/times.jpg") no-repeat;
      text-align: center;
      padding-top: 10px;
    }

    .times:hover {
      float: left;
      width: 128px;
      height: 71px;
      font-size: 18px;
      color: #FFF;
      background: url("../../assets/images/times1.jpg") no-repeat;
      text-align: center;
      padding-top: 10px;

      a {
        color: #fff;
      }
    }

    .listt, .listtbt {
      float: left;
      width: 530px;
    }

    .listt {
      height: 85px;
      padding-left: 15px;
    }

    .listtbt {
      font-size: 18px;
      height: 20px;

      a {
        color: #5c5c5c;
      }

      a:hover {
        color: #0A71FF;
      }
    }

    .listtnr {
      color: rgb(134, 134, 134);
      float: left;
      font-family: 宋体;
      font-size: 12px;
      height: 45px;
      line-height: 20px;
      padding-top: 10px;
      width: 530px;
      overflow: hidden;
    }
  }
}
</style>
