<template>
  <div >
    <WebHeader/>
    <router-view/> 
  </div>
</template>

<script>

import WebHeader from "./components/WebHeader";

export default {
  name: 'App',
  components: {
    WebHeader
  }
}
</script>

<style lang="less">
    li {
      list-style: none !important;
    }
    
    ol, ul, dl, p {
      margin-bottom: 0 !important;
    }
    
    a {
      text-decoration: none !important;
    }
    
    ul {
      display: block;
      list-style-type: disc;
      margin-block-start: 0px !important;
      margin-block-end: 0px !important;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 0px !important;
    }
    
    .page-container {
      padding-top: 48px;
    }

    .fright {
      float: right;
    }
    .tr {
      text-align: right;
    }
    
    //设置媒体查询
    @media only screen {
      // 断点 768px
      @media (min-width: 768px) {
        .page-container {
          padding-top: 0px;
        }
        .padding60 {
          padding: 60px 0;
        }

        .bg-gray {
          background: #f8f8f8;
        }

        .w1000 {
          margin: auto;
          overflow: hidden;
          width: 1000px;
        }
      }
    }
    h4{
      margin-block-start:0px;
      margin-block-end:0px;
    }
</style>
