<template>
  <div class="page-container">
    <Banner/>
    <Service/>
    <Industry/>
    <Cooperate/>
    <Cases/>
    <Customer/>
    <News/>
    <FriendLink/>
    <Footer/>
  </div>
</template>

<script>
import Banner from '../../components/Home/Banner'
import Service from '../../components/Home/Service'
import Industry from '../../components/Home/Industry'
import Cooperate from '../../components/Home/Cooperate'
import Cases from '../../components/Home/Cases'
import Customer from '../../components/Home/Customer'
import News from '../../components/Home/News'
import FriendLink from '../../components/Home/FriendLink'
import Footer from '../../components/Footer'

export default {
  name: "Home",
  components: {Banner, Service, Industry, Cooperate, Cases, Customer, News, FriendLink,Footer}
}
</script>

<style scoped lang="less">

</style>
