export default {
 
  caseRecommendList: `/api/case/recommendList`, //案例推荐列表
  caseList: `/api/case/caseList`, //案例列表
  caseDetails: `/api/case/caseDetails`, //案例列表
  casePrevAndNext: `/api/case/casePrevAndNext`, //案例的上一个，下一个id
  caseRelated: `/api/case/caseRelated`, //底部案例
  newsList: `/api/news/list`, //新闻列表
  advertiseList: `/api/adMgr/bannerList`, //广告列表
  getHomeNews: `/api/news/getNews`, //首页新闻
  getRecommendNews: `/api/news/getRecommendNews`, //推荐新闻
  getNextNews: `/api/news/getNextNews`, //下一篇新闻
  newsDetails: `/api/news/getNewsDetails`, //新闻详情
  caseTypeList: `/api/case/type/caseTypeList`, //案例类型
  newsTypeList: `/api/news/type/newsTypeList`, //新闻类型
  
}
