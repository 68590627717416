<template>
  <!--PC版-->
  <div class="main1">
    <div class="mainn">
      <div class="mleft">
        <NewsItem v-for="item in newsList" :key="item.id" :data="item"/>
        <Pagination :totalPage="pageTotal" :changePageNumber="changePageNumber"/>
      </div>
      <NewsCase/>
    </div>
  </div>
</template>

<script>
import Pagination from "../Pagination";
import axios from "../../net/axios";
import api from "../../net/api";
import NewsItem from "./NewsItem";
import NewsCase from "./NewsCase";
export default {
  name: "WebList",
  props: ['typeId'],
  components: {Pagination,NewsItem,NewsCase},
  data() {
    return {
      pageSize: 12,
      pageTotal: 0,
      newsList: [],
      pageIndex: 1,
    }
  },
  methods: {
    changePageNumber(value) {
      this.pageIndex = value;
      this.getData()
    },
    getData() {
      let params = {
        newsType: this.typeId,
        pageSize: this.pageSize,
        pageIndex: this.pageIndex,
        state:1
      }
      axios.post(api.newsList, params)
          .then(res => {
            console.log('新闻列表',res.data)
            this.newsList = res.data.rows;
            this.pageTotal = Math.ceil(res.data.total / this.pageSize)
          })
          .catch(e => {
            console.log(e)
          })
    },
  },
  watch: {
    typeId: {
      immediate: true, //初始化时调用一下
      deep: true,//深度监视
      handler() {
        // console.log('切换类型caseType')
        this.pageIndex = 1;
        this.pageTotal=0
        this.newsList=[]
        this.getData();
      }
    }
  },
}
</script>

<style scoped>

</style>
